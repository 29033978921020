import * as React from "react"
import Layout from "../../components/layout";
import {OrtName, Render} from "../../components/container/render";
import {Parts} from "../../components/orte/wohlmuthshuell";

const Page = (props) => {

    const ortName = OrtName(props.location.pathname);
    const pageTitle = "Orte der Demokratie - " + ortName;
    return (
        <Layout pageTitle={pageTitle} props={props}>
            <main id="main">
                <Render parts={Parts} ortName={ortName}/>
            </main>

        </Layout>
    )
}

export default Page
