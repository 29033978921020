import * as React from 'react'
import * as Container from "../../container/container";
import { StaticImage } from "gatsby-plugin-image";

const TextBlock2White = (key, ortName) => {
    return (
        <Container.Part background="white" key={key}>
            <Container.Row>
                <div className="col-6">
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery1"
                           href={"/images/Wohlmuthshuell/wohlmuthshuell_bild_0011.jpg"}
                           data-glightbox="title: Wahlergebnisse der Reichstagswahl-Wahl in Wohlmuthshüll und bayernweit, 5. März 1933; description: .custom-desc1_4; descPosition: bottom;">
                            <StaticImage src="../../../../static/images/Wohlmuthshuell/wohlmuthshuell_bild_0011.jpg" width={335} layout="fixed" placeholder="none"
                                         alt="Wahlergebnisse der Reichstagswahl-Wahl in Wohlmuthshüll und bayernweit, 5. März 1933"/>
                        </a>
                        <div className="glightbox-desc custom-desc1_4">
                            <p>
                                Die Bevölkerung in Wohlmuthshüll war weitgehend katholisch und bei der Reichstagswahl
                                1933 fiel das Ergebnis für die NSDAP verhältnismäßig niedrig aus.
                            </p>
                        </div>
                        <figcaption>
                            Wahlergebnisse der Reichstagswahl-Wahl in Wohlmuthshüll und bayernweit, 5. März 1933
                        </figcaption>
                    </figure>
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery1"
                           href={"/images/Wohlmuthshuell/wohlmuthshuell_bild_0006.jpg"}
                           data-glightbox="title: „Erste freie Wahl“, Bayerischer Tag, 11. August 1945; description: .custom-desc1_6; descPosition: bottom;">
                            <StaticImage src="../../../../static/images/Wohlmuthshuell/wohlmuthshuell_bild_0006.jpg" width={335} layout="fixed" placeholder="none"
                                         alt="„Erste freie Wahl“, Bayerischer Tag, 11. August 1945"/>
                        </a>
                        <div className="glightbox-desc custom-desc1_6">
                            <p>
                                Die amerikanische Militärregierung übernahm ab 1945 die Herausgabe von Zeitungen in der
                                Besatzungszone. Der Bayerische Tag erschien in Bamberg als Zeitung der 12. US-Armee.
                                Örtlicher Redakteur des Bayerischen Tags war Kurt Wittler, seine Artikel wurden mit dem
                                Kürzel K.W. gezeichnet.
                                Diese Zeitungsmeldung über die „Erste freie Wahl“ in Bayern wurde von dem Historiker
                                Wolfgang Benz aufgegriffen und fand so Eingang in die Forschung.
                            </p>
                            <p className="copy">Abb. Stadtarchiv Bamberg, ZB 8 + 11 08 1945</p>
                        </div>
                        <figcaption>
                            „Erste freie Wahl“, Bayerischer Tag, 11. August 1945
                        </figcaption>
                    </figure>
                </div>
                <div className="col-6">
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery1"
                           href={"/images/Wohlmuthshuell/wohlmuthshuell_bild_0008.jpg"}
                           data-glightbox="title: „Die Legende von der ersten freien Wahl“ von Manfred Franze, Nürnberger Zeitung, 11. August 2005; description: .custom-desc1_7; descPosition: bottom;">
                            <StaticImage src="../../../../static/images/Wohlmuthshuell/wohlmuthshuell_bild_0008.jpg" width={335} layout="fixed" placeholder="none"
                                         alt="„Die Legende von der ersten freien Wahl“ von Manfred Franze, Nürnberger Zeitung, 11. August 2005"/>
                        </a>
                        <div className="glightbox-desc custom-desc1_7">
                            <p className="copy">Abb. Nürnberger Zeitung</p>
                        </div>
                        <figcaption>
                            „Die Legende von der ersten freien Wahl“ von Manfred Franze, Nürnberger Zeitung, 11. August
                            2005
                        </figcaption>
                    </figure>
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery1"
                           href={"/images/Wohlmuthshuell/wohlmuthshuell_bild_0007.jpg"}
                           data-glightbox="title: Akten der amerikanischen Militärregierung (War Diary 1 July to 1 August), 1945; description: .custom-desc1_5; descPosition: bottom;">
                            <StaticImage src="../../../../static/images/Wohlmuthshuell/wohlmuthshuell_bild_0007.jpg" width={335} layout="fixed" placeholder="none"
                                         alt="Akten der amerikanischen Militärregierung (War Diary 1 July to 1 August), 1945"/>
                        </a>
                        <div className="glightbox-desc custom-desc1_5">
                            <p>
                                Bald nach der Kapitulation ersetzte die US-Militärverwaltung die Bürgermeister
                                aus der NS-Zeit durch neue unbelastete Bürgermeister. In den Akten der
                                Militärregierung in Ebermannstadt ist am 17. Juli 1945 eine Beschwerde des
                                kommissarischen Landrats Georg Mandt über dieses Vorgehen verzeichnet.
                            </p>
                            <p className="copy">Abb. Bayerisches Hauptstaatsarchiv, OMGUS Co-457/6</p>
                        </div>
                        <figcaption>
                            Akten der amerikanischen Militärregierung (War Diary 1 July to 1 August), 1945
                        </figcaption>
                    </figure>
                </div>
            </Container.Row>
        </Container.Part>
    )
}

export default TextBlock2White
