import * as React from 'react'
import * as Container from "../../container/container";
import { StaticImage } from "gatsby-plugin-image";

const Headerimage = (key, ortName) => {
    return (
        <Container.HeaderPart background="Wohlmuthshuell" key={key}>
            <div className="headline">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1>1945<br/>
                                Wohlmuthshüll<br/><br/>
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="box">
                            <ul>
                                <li>Kommunales</li>
                                <li>Wahlen</li>
                            </ul>
                            <p>
                                Schon vor den ersten offiziellen Kommunalwahlen der Nachkriegszeit kamen in
                                Wohlmuthshüll 1945 die Männer des Ortes zusammen, um Kandidaten für das Bürgermeisteramt
                                und den Gemeinderat zu wählen.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="image-row">
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <figure className="lightbox">
                                <a className="glightbox" data-gallery="gallery1"
                                   href={"/images/Wohlmuthshuell/wohlmuthshuell_bild_0002.jpg"}
                                   data-glightbox="description: .custom-desc1_3; descPosition: bottom;">
                                    <StaticImage src="../../../../static/images/Wohlmuthshuell/wohlmuthshuell_bild_0002.jpg" width={335} layout="fixed" placeholder="none"
                                                 alt="Foto Johann Sponsel"/>
                                </a>
                                <div className="glightbox-desc custom-desc1_3">
                                    <h2>Johann Sponsel</h2>
                                    <p>
                                        Landrat Mandt schlug der US-Militärregierung nach der Wahl in Wohlmuthshüll
                                        Johann Sponsel als Bürgermeister vor. Am 26. Juli 1945 setzten die Amerikaner
                                        ihn ein, genauso wie Bürgermeister in 23 anderen Gemeinden in der Region. Bei
                                        den ersten Kommunalwahlen am 26. Januar 1946 erhielten er und sein Gegenkandidat
                                        Franz Sponsel gleich viele Stimmen. Franz Sponsel übernahm das Amt. 1948 wurde
                                        wieder Johann Sponsel gewählt, der bis 1972 im Amt blieb.
                                    </p>
                                    <p className="copy">Abb. picture-alliance / dpa</p>
                                </div>
                                <figcaption>
                                    Johann Sponsel
                                </figcaption>
                            </figure>
                        </div>
                        <div className="col-6">
                            <figure className="lightbox">
                                <a className="glightbox" data-gallery="gallery1"
                                   href={"/images/Wohlmuthshuell/wohlmuthshuell_bild_0003.jpg"}
                                   data-glightbox="title: Gasthaus Brütting, um 1940; description: .custom-desc1_2; descPosition: bottom;">
                                    <StaticImage src="../../../../static/images/Wohlmuthshuell/wohlmuthshuell_bild_0003.jpg" width={335} layout="fixed" placeholder="none"
                                                 alt="Gasthaus Brütting, um 1940"/>
                                </a>
                                <div className="glightbox-desc custom-desc1_2">
                                    <p>
                                        Im Gasthaus Brütting in Wohlmuthshüll hielt Landrat Mandt am 18. Juli 1945 eine
                                        Versammlung zur Wahl von Kandidaten für das Bürgermeisteramt und den Gemeinderat
                                        ab.
                                    </p>
                                    <p className="copy">Abb. privat</p>
                                </div>
                                <figcaption>
                                    Gasthaus Brütting, um 1940
                                </figcaption>
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </Container.HeaderPart>
    )
}

export default Headerimage
