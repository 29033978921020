import * as React from 'react'
import * as Container from "../../container/container";
import { StaticImage } from "gatsby-plugin-image";

const TextBlock3Beige = (key, ortName) => {
    return (
        <Container.Part background="beige" key={key}>
            <Container.Row>
                <div className="col-12">
                    <h2>Wahlen in Bayern 1946</h2>
                </div>
            </Container.Row>
            <Container.Row>
                <div className="col-6">
                    <p>
                        Nach dem Ende des Zweiten Weltkriegs lag die staatliche Hoheit bei der US-Besatzungsmacht, die
                        noch im Mai 1945 einen bayerischen Ministerpräsidenten und eine Regierung einsetzte. Die
                        Erneuerung der Demokratie sollte von unten nach oben erfolgen, also von den Kommunen und den
                        Ländern ausgehend.
                    </p>
                    <p>
                        Zunächst setzte die Militärregierung in den Städten und Gemeinden neue, unbelastete Amtsträger
                        ein. Ab Ende 1945 ließ sie die Bildung politischer Parteien wieder zu. Zwischen Januar und Juni
                        1946 wurden zuerst auf der Ebene der Gemeinden, dann auf Ebene der Stadt- und Landkreise und
                        schließlich auf Landesebene Wahlen zur Verfassunggebenden Landesversammlung und zum Landtag
                        abgehalten. So folgten den zunächst kommissarisch eingesetzten Verantwortlichen dann gewählte
                        Amtsträger.
                    </p>
                </div>
                <div className="col-6">
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery2"
                           href={"/images/Wohlmuthshuell/wohlmuthshuell_bild_0012.jpg"}
                           data-glightbox="title: Ergebnis der bayerischen Kommunalwahl in den kreisangehörigen Gemeinden am 27. Januar 1946; description: .custom-desc1_9; descPosition: bottom;">
                            <StaticImage src="../../../../static/images/Wohlmuthshuell/wohlmuthshuell_bild_0012.jpg" width={335} layout="fixed" placeholder="none"
                                         alt="Ergebnis der bayerischen Kommunalwahl in den kreisangehörigen Gemeinden am 27. Januar 1946"/>
                        </a>
                        <div className="glightbox-desc custom-desc1_9"> &nbsp; </div>
                        <figcaption>
                            Ergebnis der bayerischen
                            Kommunalwahl in den
                            kreisangehörigen Gemeinden
                            am 27. Januar 1946
                        </figcaption>
                    </figure>
                </div>
            </Container.Row>
        </Container.Part>
    )
}

export default TextBlock3Beige
