import * as React from 'react'
import * as Container from "../../container/container";
import { StaticImage } from "gatsby-plugin-image";

const TextBlock4More = (key) => {
    return (
        <Container.More move="true" lessText="weniger lesen" moreText="weiter lesen" key={key}>
            <Container.Part background="white" key={key}>
                <Container.Row>
                    <div className="col-6">
                        <figure className="lightbox">
                            <a className="glightbox" data-gallery="gallery2"
                               href={"/images/Wohlmuthshuell/wohlmuthshuell_bild_0004.jpg"}
                               data-glightbox="title: Dienststelle der amerikanischen Militärregierung im heutigen Bürgerhaus in Ebermannstadt (rechts), um 1946; description: .custom-desc1_11; descPosition: bottom;">
                                <StaticImage src="../../../../static/images/Wohlmuthshuell/wohlmuthshuell_bild_0004.jpg" width={335} layout="fixed" placeholder="none"
                                             alt="Dienststelle der amerikanischen Militärregierung im heutigen Bürgerhaus in Ebermannstadt (rechts), um 1946"/>
                            </a>
                            <div className="glightbox-desc custom-desc1_11">
                                <p>
                                    Die US-Militärregierung für Bayern war mit Dienststellen in allen Landkreisen und
                                    Städten präsent. Für Wohlmuthshüll war die Militärverwaltung in Ebermannstadt
                                    zuständig.
                                </p>
                                <p className="copy">Abb. Privat</p>
                            </div>
                            <figcaption>
                                Dienststelle der amerikanischen Militärregierung im heutigen Bürgerhaus in Ebermannstadt
                                (rechts), um 1946
                                <span className="description">
                                    Die US-Militärregierung für Bayern war mit Dienststellen in allen Landkreisen und
                                    Städten präsent. Für Wohlmuthshüll war die Militärverwaltung in Ebermannstadt
                                    zuständig.</span>
                            </figcaption>
                        </figure>
                        <figure className="lightbox">
                            <a className="glightbox" data-gallery="gallery2"
                               href={"/images/Wohlmuthshuell/wohlmuthshuell_bild_0009.jpg"}
                               data-glightbox="title: „Die Wahl im Bamberger Umland“, Fränkischer Tag, 30. Januar 1946; description: .custom-desc1_13; descPosition: bottom;">
                                <StaticImage src="../../../../static/images/Wohlmuthshuell/wohlmuthshuell_bild_0009.jpg" width={335} layout="fixed" placeholder="none"
                                             alt="„Die Wahl im Bamberger Umland“, Fränkischer Tag, 30. Januar 1946"/>
                            </a>
                            <figcaption>
                                „Die Wahl im Bamberger Umland“, Fränkischer Tag, 30. Januar 1946
                            </figcaption>
                            <div className="glightbox-desc custom-desc1_13">
                                <p className="copy">Abb. Stadtarchiv Bamberg, ZA 2 + 1946 01 30</p>
                            </div>
                        </figure>
                    </div>
                    <div className="col-6">
                        <figure className="lightbox">
                            <a className="glightbox" data-gallery="gallery2"
                               href={"/images/Wohlmuthshuell/wohlmuthshuell_bild_0010.jpg"}
                               data-glightbox="title: Stimmzettel für die Bürgermeister- und Stadtratswahl in Ebermannstadt am 27. Januar 1946; description: .custom-desc1_12; descPosition: bottom;">
                                <StaticImage src="../../../../static/images/Wohlmuthshuell/wohlmuthshuell_bild_0010.jpg" width={335} layout="fixed" placeholder="none"
                                             alt="Stimmzettel für die Bürgermeister- und Stadtratswahl in Ebermannstadt am 27. Januar 1946"/>
                            </a>
                            <div className="glightbox-desc custom-desc1_12">
                                <p>
                                    Am 27. Januar 1946 fanden in Bayern die ersten Kommunalwahlen nach dem Ende des
                                    Krieges statt. Gewählt wurde in den Gemeinden mit weniger als 20.000 Einwohnern. Am
                                    28. April 1946 wurden die Kreistage gewählt.
                                </p>
                                <p className="copy">Abb. Stadtarchiv Ebermannstadt</p>
                            </div>
                            <figcaption>
                                Stimmzettel für die Bürgermeister- und Stadtratswahl in Ebermannstadt am 27. Januar 1946
                                <span className="description">
                                    Am 27. Januar 1946 fanden in Bayern die ersten Kommunalwahlen nach dem Ende des
                                    Krieges statt. Gewählt wurde in den Gemeinden mit weniger als 20.000 Einwohnern. Am
                                    28. April 1946 wurden die Kreistage gewählt.</span>
                            </figcaption>
                        </figure>
                        <figure className="lightbox">
                            <a className="glightbox" data-gallery="gallery2"
                               href={"/images/Wohlmuthshuell/wohlmuthshuell_bild_0005.jpg"}
                               data-glightbox="title: Vor einem Wahllokal in München, Mai 1946; description: .custom-desc1_14; descPosition: bottom;">
                                <StaticImage src="../../../../static/images/Wohlmuthshuell/wohlmuthshuell_bild_0005.jpg" width={335} layout="fixed" placeholder="none"
                                             alt="Vor einem Wahllokal in München, Mai 1946"/>
                            </a>
                            <div className="glightbox-desc custom-desc1_14">
                                <p>
                                    Am 26. Mai 1946 fanden Kommunalwahlen in den Stadtkreisen und Gemeinden mit mehr als 20.000 Einwohnern statt.
                                </p>
                                <p className="copy">Abb. Süddeutsche Zeitung Photo</p>
                            </div>
                            <figcaption>
                                Vor einem Wahllokal in München, Mai 1946
                                <span className="description">Am 26. Mai 1946 fanden Kommunalwahlen in den Stadtkreisen und Gemeinden mit mehr als 20.000 Einwohnern statt.</span>
                                <span className="copy">Abb. Süddeutsche Zeitung Photo</span>
                            </figcaption>
                        </figure>
                    </div>
                </Container.Row>
            </Container.Part>
        </Container.More>
    )
}

export default TextBlock4More
